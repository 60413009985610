@font-face {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("@src/assets/SpaceGrotesk-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("@src/assets/SpaceGrotesk-Regular.woff2") format("woff2");
}
